/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
// import { getTestTable } from "../../api/index";

import { unique } from "@/assets/app.js";
import { Dialog } from 'vant';
export default {
  components: {},
  props: {
    pageIdentity: {
      default: ""
    }
  },
  data() {
    return {
      searchVal: "",
      placeTxt: "请输入计划编号",
      data: [],
      mount: [],
      mountDupRe: [],
      pageNum: 1,
      prePage: 1,
      maxPageNum: 1,
      totalNum: 0,
      // pageIndex: 0,
      pageSize: 20,
      result: {},
      itemString: "",
      gysInfoData: {}
    };
  },
  created() {
    this.getCgwpInfo();
    if (this.pageIdentity == "监控结果有效性计划") {
      this.placeTxt = "请输入计划名称";
    }
    this.data = this.mount;
  },
  mounted() {},
  methods: {
    getCgwpInfo() {
      var pageNum = this.pageNum - 1;
      let formdata = new FormData();
      formdata.append("pageIndex", pageNum);
      formdata.append("pageSize", this.pageSize);
      if (this.searchVal != "") {
        if (this.pageIdentity != "监控结果有效性计划") {
          formdata.append("Q_F_JHBH_S_LK", this.searchVal);
        } else {
          formdata.append("Q_F_JHMC_S_EQ", this.searchVal);
        }
      }
      var url;
      if (this.pageIdentity != "监控结果有效性计划") {
        url = _baseUrl + `/dev/cus/customData/hqgjjh1/getData.do?single=true&_winid=w2341&_t=820763`;
      } else {
        url = _baseUrl + "/dev/cus/customData/jkjgwd/getData.do?single=true&_winid=w6640&_t=376530";
      }
      this.$ajax.post(url, formdata).then(res => {
        this.data = res.data.data;
        this.data.map(item => {
          if (JSON.stringify(this.mount).indexOf(item.identifyLabel) == -1) {
            this.mount.push(item);
          }
        });
        this.totalNum = res.data.total;
        this.maxPageNum = Math.ceil(this.totalNum / 10);
        console.log(this.mount.length);
      });
    },
    onPlus() {
      this.pageNum += 1;
      this.getCgwpInfo();
    },
    onMinus() {
      this.pageNum -= 1;
      this.getCgwpInfo();
    },
    onChange() {
      var pageNum = this.pageNum;
      var maxPageNum = this.maxPageNum;
      if (pageNum <= maxPageNum) {
        // this.prePage = pageNum;
        this.getCgwpInfo();
      } else {
        Dialog({
          message: '请正确输入页数！'
        });
      }
    },
    //搜索
    onSearch() {
      this.pageNum = 1;
      this.getCgwpInfo();
    },
    onClear() {
      this.searchVal = "";
      this.pageNum = 1;
      this.getCgwpInfo();
    },
    //确定
    onSelects() {
      // console.log(this.result)
      this.$emit("tdObj", this.result);
    }
  }
};